import styled from "styled-components"

const Button = styled.button`
  font-size: 16px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  border-radius: 40px;
  border: none;
  padding: 10px 30px 10px 30px;
  margin: 20px 0 20px 0;
  line-height: 100%;
  cursor: pointer;
  transition: background-color 0.2s;
  white-space: nowrap;
  color: white;
  background-color: #ed655a;

  ${props => {
    if (props.formButton) {
      return `
      border-radius: 0px;
      width: 100%;
      box-sizing: border-box;
      text-align: center;
      font-weight: 500;
      height: 50px;
      @media screen and (max-width: 800px) {
        height: 35px;
      }
  `
    }
  }}
  &:hover {
    background-color: #F0837B;
  }
`

export default Button