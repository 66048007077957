import React, {useState} from 'react';

import Col from "../styled-components/wrappers/Col";
import P from "../styled-components/typography/P";
import Button from "../styled-components/button/Button";
import {A, Form, Input} from "../styled-components/forms/FormComponents";

const FormGR = ({isContact, listToken}) => {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const [isError, setIsError] = useState(
        {
            firstname: {error: false, text: ""},
            lastname: {error: false, text: ""},
            email: {error: false, text: ""}
        });

    function FormErrorHandler(props) {
        if (props === "firstname") {
            if (firstName === "") {
                setIsError({
                    ...isError,
                    firstname: {error: true, text: "Kötelező mező."}
                });
            } else {
                setIsError({...isError, firstname: {error: false, text: ""}});
            }
        } else if (props === "lastname") {
            if (lastName === "") {
                setIsError({
                    ...isError,
                    lastname: {error: true, text: "Kötelező mező."}
                });
            } else {
                setIsError({...isError, lastname: {error: false, text: ""}});
            }
        } else if (props === "email") {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (email === "") {
                setIsError({
                    ...isError,
                    email: {error: true, text: "Kötelező mező."}
                });
            } else if (!re.test(String(email).toLowerCase())) {
                setIsError({
                    ...isError,
                    email: {error: true, text: "Hibás formátum."}
                });
            } else {
                setIsError({...isError, email: {error: false, text: ""}});
            }
        }
    }

    return (
        <Form action="https://balasys.getresponse360.pl/add_subscriber.html" accept-charset="utf-8" method="post">
            <Input
                id="first_name"
                name="first_name"
                variant="outlined"
                type="text"
                label="Vezetéknév:"
                required
                span={6}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                error={isError.firstname.error}
                helperText={isError.firstname.error ? isError.firstname.text : ""}
                onBlur={() => FormErrorHandler("firstname")}
            />
            <Input
                id="last_name"
                name="last_name"
                variant="outlined"
                type="text"
                label="Keresztnév:"
                required
                span={6}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                error={isError.lastname.error}
                helperText={isError.lastname.error ? isError.lastname.text : ""}
                onBlur={() => FormErrorHandler("lastname")}
            />
            <Input
                id="email"
                name="email"
                type="text"
                variant="outlined"
                label="Email-cím:"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={isError.email.error}
                helperText={isError.email.error ? isError.email.text : ""}
                onBlur={() => FormErrorHandler("email")}
            />
            <input type="hidden" name="campaign_token" value={listToken} />
            <input type="hidden" name="thankyou_url" value="https://blackowl.balasys.eu/thank-you/"/>
            {isContact &&
            <Input
                id="custom_comment"
                name="custom_comment"
                variant="outlined"
                label="Üzenet:"
                multiline
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
            />
            }
            <Col full grid>
                <P description><input id="accept" type="checkbox"/> * Elolvastam és elfogadom az <A
                    href="https://balasys.hu/hu/privacy-policy" target="_blank" rel="noreferrer">adatvédelmi
                    szabályzatot</A>!</P>
            </Col>
            <Col full grid>
                <Button
                    id="button"
                    type="submit"
                    formButton={isContact ? "true" : ""}
                >
                    {isContact ? "Üzenet elküldése" : "Regisztráció"}
                </Button>
            </Col>
        </Form>
    );
};

export default FormGR;