import styled from "styled-components";
import TextField from "@material-ui/core/TextField";

const handleGridColumn = gridColumn => {
    switch (gridColumn) {
        case 1:
            return "span 1";
        case 2:
            return "span 2";
        case 3:
            return "span 3";
        case 4:
            return "span 4";
        case 5:
            return "span 5";
        case 6:
            return "span 6";
        case 7:
            return "span 7";
        case 8:
            return "span 8";
        case 9:
            return "span 9";
        case 10:
            return "span 10";
        case 11:
            return "span 11";
        default:
            return "1/-1";
    }
};

export const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 40px 20px;
  max-width: 600px;
  width: 100%;
  margin: auto;
`

export const Label = styled.label`
  font-family: Archivo, sans-serif;
  color: #1a1725;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 5px;
  font-weight: bold;
  text-align: left;
`

export const A = styled.a`
  color: #ff2f5b;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
`

export const Input = styled(TextField)`
  font-family: "Raleway", sans-serif;
  font-size: 1.2rem;
  height: ${props => props.multiline ? "auto" : "48px"};
  grid-column: ${({span}) => handleGridColumn(span)};

  .MuiInputBase-root,
  .MuiFormLabel-root {
    color: #706b81;
  }

  .MuiOutlinedInput-root {
    & fieldset {
      border: 1px solid #a9c6ea;
      border-radius: 0;
    }

    &:hover fieldset {
      border-color: #ed655a;
    }
  }

  @media screen and (max-width: 500px) {
    grid-column: 1/-1;
  }
`